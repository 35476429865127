html,
body {
    margin: 0;
    padding: 0;
    height: 100vh;
    background: #EBEBEB;
}

@font-face {
    font-family: RobotoCondensed;
    src: url(../fonts/RobotoCondensed-Regular.ttf);
}
@font-face {
    font-family: Roboto;
    src: url(../fonts/Roboto-Regular.ttf);
}

.angel {
    display: block;
    width: 100vw;
    height: 100vh;
    background: #EBEBEB;
}

.angel__header {
    width: 100%;
    height: 80px;
    background: #FFFFFF;
    box-shadow: 0px 15px 15px rgba(0, 0, 0, 0.05);
    position: relative;
    z-index: 5;
}

.angel__logo {
    margin-left: 18px;
    width: 256px;
    height: auto;
}
.angel__video {
    display: block;
    width: 33.333333333333%;
    float: left;
    height: calc(100vh - 64px);
    text-align: center;
    max-height: calc(100vh - 64px);
}

.angel__video__wrapper {
    margin-top: 18px;
    display: inline-block;
    width: calc( 100% - 19px);
    margin: 18px 8px;
    background: #FFFFFF;
    box-shadow: 0px 4px 30px rgba(216, 216, 216, 0.5);
    border-radius: 25px;
    text-align: center;
    padding-top: 18px;
    box-sizing: border-box;
    position: relative;
}

.angel__video__wrapper .angel__video__div video {
    width: calc(100% - 32px);
    border-radius: 16px;
}

.angel__video__wrapper .angel__video__div video.with_question {
    max-height: calc(60vh - 64px);
}

.angel__video__wrapper .angel__video__div video.without_question {
    max-height: calc(80vh - 64px);
}


.angel__video__feedback {
    color: white;
    text-align: left;
    padding: 25px;
    padding-bottom: 0;
}

.angel__video__feedback__flow {
    font-family: RobotoCondensed;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 26px;
    margin-top: -120px;
    z-index: 2;
    position: relative;
}

.angel__video__feedback__flow.active{
    color: #B3DEAF;
}

.angel__video__feedback__flow #icon{
    position: absolute;
    right: 8px;
}

.angel__video__feedback__dash {
    width: 100%;
    display: block;
    height: 3px;
    background: white;
    z-index: 2;
    position: relative;
    margin: 5px 0;
}

.angel__video__feedback__step {
    font-family: RobotoCondensed;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    z-index: 2;
    position: relative;
}

.angel__video__feedback__step.active {
    color: #B3DEAF;
}

.angel__flow {
    display: block;
    width: 33.333333333333%;
    float: left;
    height: calc(100vh - 64px);
    text-align: center;
    overflow-x: scroll;
    padding: 8px;
    box-sizing: border-box;
}

.angel__flow__button {
    width: 100%;
    box-sizing: border-box;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    display: inline-block;
    text-decoration: none;
    cursor: pointer;
    font-size: 18px;
    text-align: left;
    line-height: 21px;
    color: #4C5056 !important;
    padding: 17px 20px;
    margin: 9px 0 9px 0;
    background: #FFFFFF;
    box-shadow: 0px 4px 30px rgba(216, 216, 216, 0.5);
    border-radius: 25px;
}

.angel__confirmation_text {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    text-decoration: none;
    color: #4C5056 !important;
    font-size: 25px;
}

.angel__flow__button__number {
    float: right;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    text-align: right;
    color: #C4C4C4;
}

.angel__flow__button.active {
    padding: 27px 24px;
    border-radius: 35px;
    width: 130%;
    box-sizing: border-box;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    color: #4C5056;
}

.angel__steps {
    display: block;
    width: 33.333333333333%;
    float: left;
    background: #FFFFFF;
    height: calc(100vh - 64px);
    box-shadow: 0px 15px 15px rgba(0, 0, 0, 0.05);
    padding: 18px;
    box-sizing: border-box;
}

.angel__steps__button {
    font-family: RobotoCondensed;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    cursor: pointer;
    line-height: 26px;
    color: #4C5056 !important;
    padding: 14px 14px;
    border-radius: 25px;
    float: left;
    text-decoration: none !important;
    clear: left;
    margin: 9px 0 9px 0;
}

.angel__steps__button.blue {
    background: rgba(136, 184, 255, 0.4);
    box-shadow: 0px 4px 4px rgba(108, 167, 255, 0.25), inset 0px 4px 4px rgba(255, 255, 255, 0.25);
}

.angel__steps__button.blue.active {
    border: 5px solid rgba(0, 102, 255, 0.4);
}

.angel__steps__button.red {
    background: #E7AEAE;
    box-shadow: 0px 4px 4px rgba(165, 71, 71, 0.25), inset 0px 4px 4px rgba(255, 255, 255, 0.25);
    border-radius: 25px;
}

.angel__steps__button.red.active {
    border: 5px solid rgba(255, 0, 0, 0.5);
}

.angel__steps__button.green {
    float: right;
    background: #B3DEAF;
    box-shadow: 0px 4px 4px rgba(139, 205, 133, 0.25), inset 0px 4px 4px rgba(255, 255, 255, 0.25);
}

.angel__steps__button.green.active {
    border: 5px solid rgba(26, 104, 29, 0.63);
}

.angel__steps__button:hover,
.angel__flow__button:hover {
    transition: .2s;
    transform: scale(1.02);
}

.disable-scrollbars::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* Chrome/Safari/Webkit */
}

.disable-scrollbars {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* IE 10+ */
}


.connection__button {
    font-family: RobotoCondensed;
    font-size: 16px;
    cursor: pointer;
    color: #4C5056 !important;
    padding: 12px 8px;
    border-radius: 24px;
    float: left;
    margin: 0px;
}

.connection__button.red{
    background: #E7AEAE;
    padding: 16px 18px;
    box-shadow: 0px 4px 4px rgba(165, 71, 71, 0.25), inset 0px 4px 4px rgba(255, 255, 255, 0.25);
}

.connection__button.red#offline{
    background: #E7AEAE;
    padding: 16px 18px;
    box-shadow: 0px 4px 4px rgba(165, 71, 71, 0.25), inset 0px 4px 4px rgba(255, 255, 255, 0.25);
}

.connection__button.red:active {
    border: 4px solid rgba(255, 0, 0, 0.5);
}

.connection__button.orange {
    background: #ddb895;
    padding: 16px 18px;
    box-shadow: 0px 4px 4px rgba(165, 116, 71, 0.25), inset 0px 4px 4px rgba(255, 255, 255, 0.25);
}

.connection__button.orange:active {
    border: 4px solid rgba(255, 123, 0, 0.5);
}

.connection__button.green {
    background: #B3DEAF;
    padding: 16px 18px;
    box-shadow: 0px 4px 4px rgba(139, 205, 133, 0.25), inset 0px 4px 4px rgba(255, 255, 255, 0.25);
}

.connection__button.green:active {
    border: 4px solid rgba(26, 104, 29, 0.63);
}

.connection__button.green#play {
    background: #B3DEAF;
    padding: 12px 8px;
    box-shadow: 0px 4px 4px rgba(139, 205, 133, 0.25), inset 0px 4px 4px rgba(255, 255, 255, 0.25);
}

.connection__button.blue {
    background: rgba(136, 184, 255, 0.4);
    padding: 16px 18px;
    box-shadow: 0px 4px 4px rgba(108, 167, 255, 0.25), inset 0px 4px 4px rgba(255, 255, 255, 0.25);
}

.connection__button.active {
    border: 4px solid rgba(0, 102, 255, 0.4);
}

#recaptcha-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}